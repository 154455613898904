<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer [ngClass]="{ rowSelected: liveEventId }">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'LIVE_EVENTS' | translate }}" tourAnchor="firstEventsListAnchor">
                    LIVE_EVENTS<fa-icon
                        class="ms-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_LIVE_EVENTS_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="xs"
                        (click)="tourService.start(); mixpanelService.sendEvent('start live events feature guide')"
                    ></fa-icon>
                </h1>
            </div>
            <div class="actions">
                <div class="form-inline" tourAnchor="secondEventsListAnchor">
                    <button
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.liveevents }}/new"
                        title="{{ 'ADD' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!liveEventId">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!liveEventId">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel [ngClass]="{ rowSelected: liveEventId }">
            <div class="list-container">
                <div class="list-top position-relative" #listTop>
                    <app-events-filter-form
                        [filterName]="'liveEventsFilter'"
                        [saveFilter]="true"
                        [columns]="columns"
                        [responsive]="true"
                        [showColumnSelection]="false"
                        [typeFilters]="stagesFilter"
                        (onApply)="onFilterApply($event)"
                        (onReset)="onFilterReset()"
                    ></app-events-filter-form>
                </div>

                <app-table-list
                    *ngIf="!state.typeFilters.active"
                    [tableName]="'live_event'"
                    [displayTableName]="'LIVE_EVENTS' | translate"
                    [data]="filterEventObservable | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [autoRows]="false"
                    [excelReportTitle]="'LIVE_EVENTS' | translate"
                    (selectedRowsChange)="selectedRowsChange($event)"
                >
                    <div *ngIf="selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            [editButton]="false"
                            (multiDelete)="multiDelete($event)"
                            [multiLiveEventButton]="showMultiActions"
                            (multiLiveEvents)="multiLiveEventsActions($event)"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
                <app-table-list
                    *ngIf="state.typeFilters.active"
                    [tableName]="'active_live_event'"
                    [displayTableName]="'LIVE_EVENTS' | translate"
                    [data]="filterEventObservable | async"
                    [(tableSchema)]="activeEventTableSchema"
                    [(selectedRows)]="selectedRows"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [autoRows]="false"
                    [excelReportTitle]="'LIVE_EVENTS' | translate"
                    (selectedRowsChange)="selectedRowsChange($event)"
                >
                    <div *ngIf="selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            [editButton]="false"
                            (multiDelete)="multiDelete($event)"
                            [multiLiveEventButton]="showMultiActions"
                            (multiLiveEvents)="multiLiveEventsActions($event)"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
</div>
